import React, { useEffect } from "react";
import styled from "styled-components";
import { Image } from "react-bootstrap";
import PageLayout from "../components/PageLayout";
import { Container, Row, Col } from "react-bootstrap";
import { Trans } from "react-i18next";
import { graphql } from "gatsby";
import { Script } from "gatsby"
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/reducers/rootReducer";
import { updateShopObject } from "../redux/actions/shopActions";
import { navigate } from 'gatsby';

const PaymentThankyou = () => {
  let shopDetails = useSelector((state: RootState) => state.shop);
  const dispatch = useDispatch();
  const dispatchShop = (obj) => dispatch(updateShopObject(obj) as any);

  useEffect(() => {
    if (shopDetails.paymentMade) {
      setTimeout(() => {
        dispatchShop({ paymentMade: false });
      }, 10000)
    }
  });

  if (!shopDetails.paymentMade && typeof window !== "undefined") {
    navigate('/');
  }

  return (
    <PageLayout>
      <Script>{`
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'eec.purchase',
          currencyCode: 'CAD',
          ecommerce: {
              purchase: {
                  actionField: {
                      id: ${shopDetails.oa_paId},
                      revenue: ${shopDetails.subtotal},
                      tax: ${(shopDetails.pst_amt + shopDetails.gst_amt)},
                      shipping: '',
                      coupon: '${shopDetails.isPostpaid ? shopDetails.promocode : shopDetails.prepaidPromocode}'
                  },
                  products: [{
                      name: '${shopDetails.pfCode}',
                      id: ${shopDetails.planId},
                      price: ${shopDetails.plan_fee},
                      quantity: 1
                  }]
              }
          }
        });
      `}</Script>
      <Container>
        <Row>
          <Col>
            <div style={{ textAlign: "center", paddingTop: "80px" }}>
              <Title>
                <Trans>Your Activation is Completed!</Trans>
              </Title>
              <PaddedImage width={"100px"} src="/images/checkout_Success.png" alt={"successful payment"} />
              <p>
                <Trans>Thank you for choosing PhoneBox as your wireless service provider.</Trans>
              </p>
              <p>
                <Trans>We have sent you an email with your account and payment information. Please check the email for more details.</Trans>
              </p>
              <p>
                <Trans>Your Phone Number will be provided via Email and SMS once the sim card is activated.</Trans>
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </PageLayout>
  )
}

export default PaymentThankyou;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

const PaddedImage = styled(Image)`
    padding-top:20px;
    padding-bottom:30px;
`;

const Title = styled.div`
    font-weight: 500;
    font-size: 25px;
    text-align: center;
    height: 100%;
    line-height: 40px;
    color: #0494ca;
`;

export const Head = () => {
  return (
    <>
      <script dangerouslySetInnerHTML={{
        __html: `
          (function(w, d, s, l, i) { w[l] = w[l] || []; w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' }); var f = d.getElementsByTagName(s)[0], j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f); })(window, document, 'script', 'dataLayer', 'GTM-TJKD8FQ');

          var _dcq = _dcq || [];
          var _dcs = _dcs || {};
          _dcs.account = '8316321';
        
          (function() {
            var dc = document.createElement('script');
            dc.type = 'text/javascript'; dc.async = true;
            dc.src = '//tag.getdrip.com/8316321.js';
            var s = document.getElementsByTagName('script')[0];
            s.parentNode.insertBefore(dc, s);
          })();
        `
      }}/>
    </>
  )
}